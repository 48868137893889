.event-background {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    padding: 20px; 
    box-sizing: border-box;
    padding-top: 60px; /* Add enough padding to push the content below the fixed navbar */
}

.event-container {
    display: flex; 
    justify-content: center; 
    gap: 20px; 
}

.event-wrapper {
    display: flex;
    flex-direction: column; 
    align-items: center; 
}

.event-title {
    font-size: 3em; 
    font-weight: 500; 
    margin-top: 0px; 
    margin-bottom: 20px; 
    color: #ffffff; 
}

.event-card {
    flex: 0 1 400px; 
    width: 550px;
    height: 350px;
    background-color: rgba(1, 21, 1, 0.5); 
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(34, 197, 94, 0.3);
    margin-bottom: 20px; 
}

.event-card-image {
    width: 100%;
    height: 80%; 
    object-fit: block;
    display: inline-block; 
}

.event-details {
    padding: 8px;
    color: #fff;
    text-align: center;
    flex-grow: 1; 
}
.event-name {
    font-size: 1.5em;
    margin: 0;
}

.event-date {
    margin: 0;
    color: #ccc;
    font-size: 1em;
}

.learn-more-button {
    width: 400px; 
    height: 40px; 
    padding: 0;
    font-size: 1em;
    color: #ffffff;
    background-color: rgb(0,21,0,0.5);
    border: 3px solid #0b9b2f;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.learn-more-button:hover {
    background-color: #02301a;
    color: #0f1c0f;
}

/* Media query for medium devices (769px to 1200px) */
@media (max-width: 1200px) and (min-width: 769px) {
    .event-container {
        display: flex; 
        flex-direction: row; 
        flex-wrap: wrap;
        justify-content: center; 
        gap: 20px; 
    }

    .event-wrapper {
        display: flex;
        flex-direction: column; 
        align-items: center; 
        width: 100%; 
        max-width: 450px;
    }

    .event-card {
        width: 100%; 
        max-width: 450px; 
        max-height: 300px;
    }

    .event-details {
        text-align: center;
    }

    .event-card-image {
        max-width: 100%; 
        height: auto; 
    }

    .learn-more-button {
        width: 100%; 
        max-width: 400px; 
    }
}

/* Media query for small devices */
@media (max-width: 768px) {
    .event-container {
        flex-direction: column; /* Stack cards vertically */
    }

    .event-card {
        width: 100%; 
        max-height: 380px;
    }

    .event-card-image {
        width: 100%;
        height: 300px; 
        object-fit: block;
        display: block;
    }

    .learn-more-button {
        width: 100%; /* Ensure the button takes full width */
        max-width: 400px;
        height: 40px; /* Maintain a consistent height */
        padding: 0;
        font-size: 1em;
        color: #ffffff;
        background-color: rgba(0, 21, 0, 0.5); /* Keep background styling consistent */
        border: 3px solid #0b9b2f;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin: 10px auto; /* Center the button on mobile */
        display: block; /* Ensure the button is visible */
    }

    .learn-more-button:hover {
        background-color: #02301a;
        color: #0f1c0f;
    }
}
