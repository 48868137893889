@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #000; /* Dark background color */
}

.home-container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center; /* Ensure everything inside is centered */
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%; /* Ensure the content takes up the full width */
    position: relative;
}

.logo-container {
    margin-bottom: 20px;
}

.logo {
    width: 150px;
    animation: revolve3d 7s linear infinite;
    transform-style: preserve-3d;
}

@keyframes revolve3d {
    from {
        transform: rotateY(0deg) translateZ(0px);
    }
    to {
        transform: rotateY(360deg) translateZ(0px);
    }
}

.title {
    text-align: center;
    color: white;
    font-family: 'Amaranth', sans-serif;
    font-size: 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    letter-spacing: 2px;
    z-index: 1;
    line-height: 1.2;
    width: 100%; /* Make sure the title takes up the full width */
}

.title h1 {
    margin: 0; /* Ensure no extra margins are applied */
    padding: 0;
    font-size: 50px;
    width: 100%;
    text-align: center; /* Center the text */
}

@media only screen and (max-width: 600px) {
    .title h1 {
        font-size: 30px; /* Adjust font size for smaller screens */
    }
}