@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Navbar container */
.navbar {
  background-color: #0F0F0F;
  padding: 20px 20px;
  display: flex;
  justify-content: center; /* Center everything for large screens */
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 5px 5px -4px rgba(0, 255, 0, 0.7);
}

/* Create space between the navbar and the content */
body {
  margin-top: 100px; /* Adjust this value based on the height of your navbar */
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  position: absolute;
  left: 20px; /* Place hamburger on the left */
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center; /* Center the links horizontally */
  gap: 30px;
  opacity: 0; /* Initially invisible */
  visibility: hidden; /* Hide the menu off-screen */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.navbar-links.open {
  opacity: 1; /* Fade in the links */
  visibility: visible; /* Show the menu */
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-family: 'Lato';
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px 15px; /* Reduced padding for less gap */
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}

.navbar-links li a:hover {
  color: #4CAF50;
  border-bottom: 2px solid #4CAF50;
}

/* Media queries for responsiveness */
@media screen and (max-width: 890px) {
  .hamburger {
    display: flex; /* Show the hamburger icon on small screens */
  }

  .navbar-links {
    position: absolute;
    top: 100%; /* Position below the navbar */
    left: 0;
    right: 0;
    background-color: #0F0F0F;
    flex-direction: column; /* Stack links vertically */
    justify-content: center;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Transition for fade effect */
  }

  .navbar-links.open {
    height: auto; /* Adjust height to auto to fit all links */
    max-height: 100vh; /* Make sure it doesn't exceed the viewport */
    padding-bottom: 10px; /* Add padding to prevent cut-off */
    opacity: 1;
    visibility: visible; /* Make the links visible */
  }

  .navbar-links li a {
    padding: 1px 15px;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    display: block; /* Ensure block layout for better spacing */
  }
}

@media screen and (min-width: 891px) {
  .hamburger {
    display: none; /* Hide hamburger on larger screens */
  }

  .navbar-links {
    display: flex;
    justify-content: center; /* Ensure links are centered on large screens */
    opacity: 1; /* Always visible on large screens */
    visibility: visible; /* Ensure visibility on large screens */
  }
}