.App {
  text-align: center;
  color: white;
  padding: 70px;
  padding-top: 20px;
}

h1 {
  font-size: 3rem;
  font-family: 'Antic Didone';
  font-weight: bold;
  margin: 90px 0;
}

.core-section, .heads-section, .domain-section { 
  margin: 20px 0;
  padding: 5px;
}

.core-section h2, .heads-section h2, .domain-section h2 {
  font-family: 'Lato';
  font-size: 30px;
  padding: 20px;
}

.core-container, .heads-container, .domain-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.core-member, .head-member, .domain-member {
  background-color: #0f0f0f;
  border-radius: 10px;
  width: 150px;
  text-align: center;
}

.core-member h3, .head-member h3, .domain-member h3 {
  font-weight: bold;
  font-family: 'Lato';
}

.core-member p, .head-member p, .domain-member p {
  font-family: 'Abel';
}

.core-image, .head-image, .domain-image {
  background-color: #777;
  height: 150px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.domain-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.domain-buttons button {
  margin: 5px;
  padding: 10px 20px; /* Adjust padding for a rectangle shape */
  background-color: #444;
  color: white;
  border: none;
  border-radius: 10px; /* Slightly rounded corners */
  cursor: pointer;
  font-family: 'Lato';
  width: 120px;  /* Set a rectangular width */
  height: 40px; /* Set a fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjust font size */
}

.domain-buttons .active {
  background-color: rgb(0, 255, 89);
  color: black;
}

@media (max-width: 768px) {
  .domain-buttons button {
    width: 100px;  /* Smaller width on smaller screens */
    height: 35px;  /* Smaller height */
    font-size: 12px; /* Smaller font */
    margin: 5px;
  }
}

@media (max-width: 480px) {
  .domain-buttons button {
    width: 90px;  /* Even smaller for very small screens */
    height: 30px;
    font-size: 11px;
    margin: 5px;
  }
}

.core-image, .head-image, .domain-image {
  object-fit: cover;
}

.core-container, .heads-container, .domain-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.core-member, .head-member, .domain-member {
  margin: 10px;
  text-align: center;
}

.domain-member img {
  width: 100%;
}

.core-member img, .head-member img {
  width: 100%;
}
