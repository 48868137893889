body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
  /* background-image: url('/public/assets/SQCC_bg\ 1.png');  */
  background-size: cover; /* Make sure the background covers the entire page */
  background-position: 30% 30%;
  background-attachment:scroll; /* Ensure the background stays fixed during scrolling */
  background-repeat: repeat; /* Prevent the background from repeating */
}

