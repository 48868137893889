@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap');

.app {
  text-align: center;
  color: white;
}

.about {
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  margin: 0px auto;
  max-width: 900px;
}

.about h1 {
  font-family: 'Abel';
  font-size: 50px;
  margin-top: 0;
  margin-bottom: 30px;
  color: #ffffff;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.about-content p {
  font-family: 'Antic Didone';
  font-size: 16px;
}

.vision-mission {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-top: 100px;
  padding-bottom: 50px;
  flex-wrap: wrap;
}

.hex {
  width: 280px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #41FFB7;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  color: rgb(0, 0, 0);
  font-weight: bold;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s;
  margin-bottom: 30px;
  text-align: center;
  overflow: hidden;
}

.hex:hover {
  transform: scale(1.05);
}

.hex h2 {
  margin: 5px 0;
  font-size: 22px;
  font-family: 'Lato';
}

.hex p {
  font-size: 14px;
  font-family: 'Lato';
  margin-top: 10px;
  line-height: 1.6;
  max-width: 180px;
}

@media (max-width: 1024px) {
  .vision-mission {
    gap: 40px;
  }

  .hex {
    width: 240px;
    height: 260px;
    padding: 10px;
  }

  .hex h2 {
    font-size: 18px;
  }

  .hex p {
    font-size: 12px;
    max-width: 100px;
  }
}

@media (max-width: 768px) {
  .vision-mission {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .hex {
    width: 90%; /* Full width with padding */
    max-width: 320px; /* Max width to ensure hexagon size remains consistent */
    height: 350px; /* Increase hexagon size */
    padding: 20px;
    margin: 0 auto;
  }

  .hex h2 {
    font-size: 16px; /* Reduce text size slightly */
  }

  .hex p {
    font-size: 12px; /* Adjust text for mobile */
    max-width: 70%; /* Make sure the text fits well inside the hexagon */
    white-space: normal;
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .vision-mission {
    gap: 10px;
    padding: 10px;
  }

  .hex {
    width: 90%; /* Full width with padding */
    max-width: 280px; /* Ensure the hexagon doesn't stretch too wide */
    height: 280px; /* Set height for a responsive hexagon */
    padding: 20px;
  }

  .hex h2 {
    font-size: 14px; /* Further reduce text size */
  }

  .hex p {
    font-size: 12px; /* Adjust text size further */
    max-width: 70%; /* Make sure text wraps properly */
    white-space: normal;
  }
}

/* Newsletter section */
.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
}

.newsletter h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #50d1a4;
}

/* Newsletter card with dashed border and circles */
.newsletter-card {
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 0; /* Remove padding so image fills the card */
  border: 3px dashed black; /* Dashed border */
  border-radius: 15px;
  background-color: transparent; /* Remove background */
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
}

.newsletter-card::before {
  content: '';
  position: absolute;
  top: -20px;
  left: calc(50% - 45px);
  width: 90px;
  height: 25px;
  display: flex;
  justify-content: space-between;
}

.newsletter-card::after {
  content: '';
  position: absolute;
  top: -20px;
  left: calc(50% - 60px);
  width: 120px;
  height: 20px;
  background-color: transparent;
  display: flex;
  justify-content: space-around;
}

.newsletter-card::after div {
  width: 20px;
  height: 20px;
  background-color: orange; /* Decorative circles */
  border-radius: 50%;
}

.newsletter-slider {
  display: flex;
  transition: transform 1s ease-in-out;
}

.newsletter-item {
  min-width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0; /* Remove padding so image fills the space */
  box-sizing: border-box;
}

/* Image inside newsletter - make the image fill the whole card */
.newsletter-content img {
  width: 100%; /* Make image take up full width of card */
  height: 100%; /* Make image take up full height of card */
  object-fit: cover; /* Ensure the image covers the entire area */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  background-color: transparent; /* No background color */
  border: none; /* Remove border */
  border-radius: 0; /* No border-radius to keep the image squared */
}

/* Responsive Newsletter styles */
@media (max-width: 768px) {
  .newsletter-card {
    max-width: 90%;
  }

  .newsletter-item {
    height: auto;
  }

  .newsletter-content h3 {
    font-size: 24px;
  }

  .newsletter h2 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .newsletter-card {
    max-width: 95%;
  }

  .newsletter-item {
    height: auto;
  }

  .newsletter-content h3 {
    font-size: 18px;
  }

  .newsletter h2 {
    font-size: 22px;
  }
}
