.contact-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-size: cover; 
    background-position: center; 
    color: white;
    padding: 20px; /* Add padding to handle smaller screens */
}

.contact-form-container {
    background-color: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    border-radius: 8px;
    max-width: 450px;
    width: 100%;
}

.contact-title {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0px;
    font-family: 'Lato'; 
    font-size: 2em;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-input, .contact-textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 2px solid #41FFB7; 
    border-radius: 10px;
    background-color: transparent;
    color: #fff;
}

.contact-textarea {
    height: 100px;
    resize: none; 
}

.contact-submit-button {
    width: 100px;
    padding: 10px;
    background-color: black; 
    border: 2px solid #41FFB7; 
    border-radius: 4px;
    color: #41FFB7; 
    cursor: pointer;
    font-weight: bold;
    margin-left: auto; 
    display: block;
}

.contact-submit-button:hover {
    background-color: #0a502e; 
}

/* For tablet screens and larger phones */
@media screen and (max-width: 768px) {
    .contact-form-container {
        max-width: 400px; /* Reduce width slightly for smaller screens */
        padding: 15px;
    }

    .contact-title {
        font-size: 1.6em; /* Reduce font size */
    }

    .contact-input, .contact-textarea {
        padding: 8px; /* Reduce padding to save space */
    }

    .contact-submit-button {
        width: 90px;
        padding: 8px;
    }
}

/* For smaller phones and very small screens */
@media screen and (max-width: 480px) {
    .contact-form-container {
        max-width: 320px;  /* Further reduce the container width for mobile */
        padding: 10px;
    }

    .contact-title {  
        font-size: 1.4em; /* Reduce font size further */
        margin-bottom: 15px;
    }

    .contact-input, .contact-textarea {
        padding: 6px; /* Reduce padding even more */
        font-size: 0.9em; /* Adjust font size inside inputs */
    }

    .contact-submit-button {
        width: 80px;
        padding: 7px;
        font-size: 0.9em; /* Adjust button font size */
    }
}