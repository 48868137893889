.achievements-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.achievements-container h1 {
    margin-top: 100px;
    font-size: 3rem;
    margin-bottom: 50px;
    color: #ccc;
    font-family: 'Lato';
    padding: 20px;
}

.achievements-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Shared achievement styles */
.achievement {
    display: flex;
    align-items: center;
    background-color: #000000;
    width: 850px;
    height: 260px;
    margin: 40px 0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(193, 204, 194, 0.5); /* Adding shadow for depth */
    position: relative;
}

.achievement h2{
    color: #ccc;
}

.achievement p{
    color: #ccc;
}

/* Placeholder image */
.image-placeholder {
    width: 187px;
    height: 173px;
    background-color: #ccc;
    border-radius: 8px;
}

/* Text container */
.achievement-text {
    text-align: left;
    flex-grow: 1;
    margin-left: 20px;
}

.achievement-text h2 {
    margin-bottom: 10px;
}

.achievement-text p {
    font-size: 0.9em;
}

/* Aligning whole boxes (left and right) */
.a1 {
    position: relative;
    left: 0; /* Align to the left */
}

.a2 {
    position: relative;
    right: 0; /* Align to the right */
    margin-left: auto;
}

.a3 {
    position: relative;
    left: 0; /* Align to the left */
}

.a4 {
    position: relative;
    right: 0; /* Align to the right */
    margin-left: auto;

}

.a5 {
    position: relative;
    left: 0; /* Align to the right */

}

.a1 h2, 
.a2 h2, 
.a3 h2, 
.a4 h2, 
.a5 h2
{
    font-family: 'Lato';
    font-size: 30px;
    font-weight: bold;    
}

.a1 p, 
.a2 p, 
.a3 p, 
.a4 p, 
.a5 p{
font-family: 'Lato';
font-weight: bold;
}

.achievement-image {
    width: 100px; /* Adjust width as per design */
    height: 100px; /* Adjust height as per design */
    object-fit: cover; /* Ensure images fit within the given size */
    border-radius: 50%; /* Optional: Make the image circular */
    margin-right: 20px; /* Optional: Add some spacing between image and text */
  }  

/* Mobile Styles */
@media (max-width: 990px) {
    .achievement {
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 50px 0; /* Add more space between items */
        padding: 20px;
    }

    .achievement-text {
        text-align: center;
        margin: 10px 0;
    }
    
    .image-placeholder {
        margin-bottom: 10px;
    }

    /* Reset box alignment on mobile */
    .a1, .a2, .a3, .a4, .a5 {
        left: auto;
        right: auto;
        margin: 0 auto;
    }
}

/* Mobile Narrow Styles (for very small screens) */
@media (max-width: 990px) {
    h1 {
        font-size: 1.8em;
    }

    .achievement-text p {
        font-size: 0.8em;
    }

    .achievement {
        margin: 60px 0; /* Add more space between items for very small screens */
        padding: 30px;
    }
}